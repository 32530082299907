<template>
  <div />
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'
  export default {
    name: 'CloseSession',
    created () {
      localStorage.clear()
      this.setUser(null)
      this.setParentsBusiness([])
      this.$router.push('/login')
    },
    methods: {
      ...mapActions([
        'setUser',
      ]),
      ...mapMutations({
        setParentsBusiness: 'FETCH_PARENTS_BUSINESS_SUCCESS',
      }),
    },
  }
</script>
